import React, {Component} from 'react'
import '../style/Credits.css'

class Credits extends Component {

  componentDidUpdate(prevProps, prevState) {
    if (this.props.display !== prevProps.display) {

      const _this = this
      // if activeCase, don't show case name, only show credits for active case
      let creditsActiveCase = []
      // if !activeCase, show all credits for all cases, sort by & show case names
      let creditsAll = []
      let casesAll = []

      if ((this.props.dataCredits) && (this.props.dataCases)) {
        creditsActiveCase = this.props.dataCredits.filter((obj) => {
          return obj['case_id'].toLowerCase() === _this.props.activeCase
        })
        creditsAll = this.props.dataCredits
        casesAll = this.props.dataCases
        if (this.props.display !== 'error') {
          if (
            (this.props.activeCase && (creditsActiveCase.length < 1)) ||
            (creditsAll.length < 1) ||
            (casesAll.length < 1)
          ) {
            this.props.handlerDataError('Credits.js - missing cases and credits data')
          }
        }
      }

    }

  }

  componentDidMount() {
    this.props.handlerComponentLoaded('Credits')
  }

  render() {

    const _this = this

    let headerLanguageActive = {
      'english': 'Credits',
      'spanish': 'Credits Spanish',
      'chinese': 'Credits Chinese',
      'filipino': 'Credits Filipino'
    }
    if (this.props.dataUI) {
      let label = this.props.dataUI.filter((ui) => {
        return ui['label_id'] === 'credits-title'
      })
      if (label.length > 0) {
        if (label[0]['label_english'] &&
        label[0]['label_spanish'] &&
        label[0]['label_chinese'] &&
        label[0]['label_filipino']) {
          headerLanguageActive = {
            'english': label[0]['label_english'],
            'spanish': label[0]['label_spanish'],
            'chinese': label[0]['label_chinese'],
            'filipino': label[0]['label_filipino']
          }
        }
      }
    }

    // if activeCase, don't show case name, only show credits for active case
    let creditsActiveCase = []
    // if !activeCase, show all credits for all cases, sort by & show case names
    let creditsAll = []
    let casesAll = []

    if ((this.props.dataCredits) && (this.props.dataCases) && (this.props.dataCasesGroups)) {
      creditsActiveCase = this.props.dataCredits.filter((obj) => {
        return obj['case_id'].toLowerCase() === _this.props.activeCase
      })
      creditsAll = this.props.dataCredits
      casesAll = this.props.dataCases.filter((item) => {
        return _this.props.dataCasesGroups.find((group) => {
          return group['case_nid'] === item['case_nid']
        })
      })
    }

    let rows = []

    if (this.props.activeCase) {
      let groups = []
      let groupHeaderLast = ''
      creditsActiveCase.forEach((obj, i) => {
        if (
          obj['credits_group_header_' + _this.props.language] &&
          obj['credits_header_' + _this.props.language] &&
          obj['credits_description_' + _this.props.language]
        ) {
          let groupHeader = []
          if (groupHeaderLast !== obj['credits_group_header_' + _this.props.language]) {
            groupHeader.push(<h3 key={'h3-'+i}>{obj['credits_group_header_' + _this.props.language]}</h3>)
          }
          let markupDesc = {__html: obj['credits_description_' + _this.props.language]}
          groups.push(
            <li
              key={i}
              >
                {groupHeader}
                <div
                  key={'credit-header-'+i}
                  className={'credit-header'}
                  >
                  {obj['credits_header_' + _this.props.language]}
                </div>
                <div
                  key={'credit-description-'+i}
                  className={'credit-description'}
                  dangerouslySetInnerHTML={markupDesc}
                  >
                </div>
            </li>
          )
          groupHeaderLast = obj['credits_group_header_' + _this.props.language]
        }
      })
      rows = groups
    } else {
      let cases = []
      let caseHeaderLast = ''
      let cid = ''
      casesAll.forEach((c, i) => {
        if (
          c['case_name_' + _this.props.language] &&
          c['case_id']
        ) {
          cid = c['case_id']
          let caseHeader = []
          if (caseHeaderLast !== c['case_name_' + _this.props.language]) {
            caseHeader.push(<h2 key={'h2-'+i}>{c['case_name_' + _this.props.language]}</h2>)
          }

          let groups = []
          let groupHeaderLast = ''
          creditsAll.forEach((obj, i) => {
            if (
              obj['case_id'] &&
              obj['credits_group_header_' + _this.props.language] &&
              obj['credits_header_' + _this.props.language] &&
              obj['credits_description_' + _this.props.language]
            ) {
              if (obj['case_id'] === cid) {
                let groupHeader = []
                if (groupHeaderLast !== obj['credits_group_header_' + _this.props.language]) {
                  groupHeader.push(<h3 key={'h3-'+i}>{obj['credits_group_header_' + _this.props.language]}</h3>)
                }
                let markupDesc = {__html: obj['credits_description_' + _this.props.language]}
                groups.push(
                  <li
                    key={'group-'+i}
                    >
                      {groupHeader}
                      <div
                        key={'credit-header-'+i}
                        className={'credit-header'}
                        >
                        {obj['credits_header_' + _this.props.language]}
                      </div>
                      <div
                        key={'credit-description-'+i}
                        className={'credit-description'}
                        dangerouslySetInnerHTML={markupDesc}
                        >
                      </div>
                  </li>
                )
                groupHeaderLast = obj['credits_group_header_' + _this.props.language]
              }
            }
          })

          cases.push(
            <ul
              key={'case-'+i}
              >
                {caseHeader}
                {groups}
            </ul>
          )
          caseHeaderLast = c['case_name_' + _this.props.language]
        }
      })
      rows = cases
    }

    return(
      <div id="credits">
        <div id="btn-close"
          onClick={() => this.props.handlerCloseCredits()}
        />
        <h1>{headerLanguageActive[this.props.language]}</h1>
        <div id="credits-list">
          {rows}
        </div>
      </div>
    )
  }
}

export default Credits
