import React, {Component} from 'react'
import '../style/Attract.css'

class Attract extends Component {
  constructor(props) {
    super(props)
    this.state = {
      updated: false,
      caseNameAnimating: false
    }
    this.handlerInterruptAttract = this._interruptAttract.bind(this)
    this.handlerInterruptAttractAndSelect = this._interruptAttractAndSelect.bind(this)
    this.timeoutIdAnimLoop = null
    this.timeoutIdAnimPreReLoop = null
    this.timeoutIdAnimReLoop = null
    this.timeoutIdHideGroups = null
    this.timeoutIdAnimCaseName = null
    this.timeoutIdAnimLoopHelper = null
    this.timeoutIdAnimLoopReRe = null
    this.timeoutIdAnimReLoopExtra = null
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.display !== this.props.display) {
      clearTimeout(this.timeoutIdAnimLoop)
      clearTimeout(this.timeoutIdAnimPreReLoop)
      clearTimeout(this.timeoutIdAnimReLoop)
      clearTimeout(this.timeoutIdHideGroups)
      clearTimeout(this.timeoutIdAnimCaseName)
      clearTimeout(this.timeoutIdAnimLoopHelper)
      clearTimeout(this.timeoutIdAnimLoopReRe)
      if (this.props.display === 'attract') {
        const _this = this
        let activeCase = []
        let activeCaseGroups = []
        if ((this.props.dataCases) && (this.props.activeCase)) {
          activeCase = this.props.dataCases.filter((obj) => {
            return obj['case_id'].toLowerCase() === _this.props.activeCase
          })
          if (this.props.dataCasesGroups && activeCase.length > 0) {
            activeCaseGroups = this.props.dataCasesGroups.filter((obj) => {
              return obj['case_nid'] === activeCase[0]['case_nid']
            })
          }
        }
        if (activeCaseGroups.length > 0) {
          //const _this = this
          this._initFade(activeCaseGroups.length)
          clearTimeout(this.timeoutIdAnimLoop)
          this.timeoutIdAnimLoop = setTimeout(() => {
            this._animLoop(1, activeCaseGroups.length)
          }, 3000)
          this._resizeCaseNameToFit()
          this.setState({
            updated: true
          })
        }
      }
    }
  }

  componentDidMount() {
    this.props.handlerComponentLoaded('Attract')
  }

  _resetAttractComponents(max) {
    clearTimeout(this.timeoutIdAnimLoop)
    clearTimeout(this.timeoutIdAnimPreReLoop)
    clearTimeout(this.timeoutIdAnimReLoop)
    clearTimeout(this.timeoutIdHideGroups)
    clearTimeout(this.timeoutIdAnimCaseName)
    clearTimeout(this.timeoutIdAnimLoopHelper)
    clearTimeout(this.timeoutIdAnimLoopReRe)
    let j = 0
    while (j < max) {
      let elImage = document.getElementById('attract-group-image-' + j)
      let elButton = document.getElementById('attract-group-button-' + j)
      let elName = document.getElementById('attract-group-name-' + j)
      let elBarGroupName = document.getElementById('attract-group-name-dna-bar-' + j)
      if (elImage && elButton && elName && elBarGroupName) {
        elImage.classList.remove('highlight')
        elButton.classList.remove('highlight')
        elName.classList.remove('highlight')
        elImage.classList.remove('fadedown')
        elButton.classList.remove('fadedown')
        elName.classList.remove('fadedown')
        elBarGroupName.classList.remove('grow')
      }
      j++
    }
    let caseTitle = document.getElementById('attract-case-title')
    let caseName = document.getElementById('attract-case-name-container')
    let groupsImage = document.getElementById('attract-groups-images')
    let groupsButton = document.getElementById('attract-groups-buttons')
    let groupsName = document.getElementById('attract-groups-names')
    let caseMask = document.getElementById('attract-mask-top')
    let caseCTA = document.getElementById('case-cta-see-all')
    let caseNameTranslations = document.getElementById('attract-case-name-translations')
    let caseNameText = document.getElementById('attract-case-name')
    let caseNameTextSpanish = document.getElementById('attract-case-name-spanish')
    let caseNameTextChinese = document.getElementById('attract-case-name-chinese')
    let caseNameTextFiliino = document.getElementById('attract-case-name-filipino')
    let elBarCaseNameBottom = document.getElementById('attract-case-name-dna-bar-bottom')
    let elBarCaseNameTopA = document.getElementById('attract-case-name-dna-bar-top-a')
    let elBarCaseNameTopB = document.getElementById('attract-case-name-dna-bar-top-b')
    if (caseTitle && caseName && groupsImage && groupsButton && groupsName && caseMask && caseCTA && caseNameText && caseNameTranslations && caseNameTextSpanish && caseNameTextChinese && caseNameTextFiliino && elBarCaseNameBottom && elBarCaseNameTopA && elBarCaseNameTopB) {
      caseTitle.classList.remove('highlight')
      groupsImage.classList.remove('highlight')
      groupsButton.classList.remove('highlight')
      groupsName.classList.remove('highlight')
      caseMask.classList.remove('highlight')
      caseCTA.classList.remove('highlight')
      caseTitle.classList.remove('fadedown')
      groupsImage.classList.remove('fadedown')
      groupsButton.classList.remove('fadedown')
      groupsName.classList.remove('fadedown')
      caseMask.classList.remove('fadedown')
      caseCTA.classList.remove('fadedown')
      caseName.classList.remove('come-and-go')
      caseNameTranslations.classList.remove('come-and-go')
      caseNameText.classList.remove('color-trans')
      caseNameTextSpanish.classList.remove('color-trans')
      caseNameTextChinese.classList.remove('color-trans')
      caseNameTextFiliino.classList.remove('color-trans')
      elBarCaseNameBottom.classList.remove('grow')
      elBarCaseNameTopA.classList.remove('grow')
      elBarCaseNameTopB.classList.remove('grow')
    }
  }

  _resetAttractComponentsForLoop(max) {
    clearTimeout(this.timeoutIdAnimLoop)
    clearTimeout(this.timeoutIdAnimPreReLoop)
    clearTimeout(this.timeoutIdAnimReLoop)
    clearTimeout(this.timeoutIdHideGroups)
    clearTimeout(this.timeoutIdAnimCaseName)
    clearTimeout(this.timeoutIdAnimLoopHelper)
    clearTimeout(this.timeoutIdAnimLoopReRe)
    let j = 0
    while (j < max) {
      let elImage = document.getElementById('attract-group-image-' + j)
      let elButton = document.getElementById('attract-group-button-' + j)
      let elName = document.getElementById('attract-group-name-' + j)
      let elBarGroupName = document.getElementById('attract-group-name-dna-bar-' + j)
      if (elImage && elButton && elName && elBarGroupName) {
        elImage.classList.remove('highlight')
        elButton.classList.remove('highlight')
        elName.classList.remove('highlight')
        elImage.classList.remove('fadedown')
        elButton.classList.remove('fadedown')
        elName.classList.remove('fadedown')
        elBarGroupName.classList.remove('grow')
        elBarGroupName.style.width = 0
      }
      j++
    }
    let caseTitle = document.getElementById('attract-case-title')
    let caseName = document.getElementById('attract-case-name-container')
    let groupsImage = document.getElementById('attract-groups-images')
    let groupsButton = document.getElementById('attract-groups-buttons')
    let groupsName = document.getElementById('attract-groups-names')
    let caseNameTranslations = document.getElementById('attract-case-name-translations')
    let caseNameText = document.getElementById('attract-case-name')
    let caseNameTextSpanish = document.getElementById('attract-case-name-spanish')
    let caseNameTextChinese = document.getElementById('attract-case-name-chinese')
    let caseNameTextFiliino = document.getElementById('attract-case-name-filipino')
    let elBarCaseNameBottom = document.getElementById('attract-case-name-dna-bar-bottom')
    let elBarCaseNameTopA = document.getElementById('attract-case-name-dna-bar-top-a')
    let elBarCaseNameTopB = document.getElementById('attract-case-name-dna-bar-top-b')
    if (caseTitle && caseName && groupsImage && groupsButton && groupsName && caseNameText && caseNameTranslations && caseNameTextSpanish && caseNameTextChinese && caseNameTextFiliino && elBarCaseNameBottom && elBarCaseNameTopA && elBarCaseNameTopB) {
      caseTitle.classList.remove('highlight')
      groupsImage.classList.remove('highlight')
      groupsButton.classList.remove('highlight')
      groupsName.classList.remove('highlight')
      caseTitle.classList.remove('fadedown')
      groupsImage.classList.remove('fadedown')
      groupsButton.classList.remove('fadedown')
      groupsName.classList.remove('fadedown')
      caseName.classList.remove('come-and-go')
      caseNameTranslations.classList.remove('come-and-go')
      caseNameText.classList.remove('color-trans')
      caseNameTextSpanish.classList.remove('color-trans')
      caseNameTextChinese.classList.remove('color-trans')
      caseNameTextFiliino.classList.remove('color-trans')
      elBarCaseNameBottom.classList.remove('grow')
      elBarCaseNameTopA.classList.remove('grow')
      elBarCaseNameTopB.classList.remove('grow')
      // hide for reloop
      caseTitle.style.opacity = 0
      groupsImage.style.opacity = 0
      groupsButton.style.opacity = 0
      groupsName.style.opacity = 0
    }
  }

  _interruptAttract(max) {
    this._resetAttractComponents(max)
    this.props.handlerCloseAttract()
    this.setState({
      caseNameAnimating: false
    })
  }

  _interruptAttractAndSelect(e, max, group, prev) {
    if (this.state.caseNameAnimating) {
      this._resetAttractComponents(max)
      this.props.handlerCloseAttract()
    } else {
      this._resetAttractComponents(max)
      this.props.handlerSelectGroup(e, group, prev)
    }
    this.setState({
      caseNameAnimating: false
    })
  }

  _initFade(max) {
    if (this.props.display === 'attract') {

      // in case return to attract display, reset opacity
      let caseTitle = document.getElementById('attract-case-title')
      let groupsImage = document.getElementById('attract-groups-images')
      let groupsButton = document.getElementById('attract-groups-buttons')
      let groupsName = document.getElementById('attract-groups-names')
      if (caseTitle && groupsImage && groupsButton && groupsName) {
        caseTitle.style.opacity = 1
        groupsImage.style.opacity = 1
        groupsButton.style.opacity = 1
        groupsName.style.opacity = 1
      }

      let caseMask = document.getElementById('attract-mask-top')
      let caseCTA = document.getElementById('case-cta-see-all')
      let elName = document.getElementById('attract-group-name-0')
      let elBarGroupName = document.getElementById('attract-group-name-dna-bar-0')
      if (caseMask && caseCTA && elName && elBarGroupName) {
        caseCTA.classList.add('fadedown')
        caseMask.classList.add('highlight')
        elName.classList.add('highlight')
        elBarGroupName.classList.add('grow')
      }
      let i = 1 // don't fade down first object initially
      while (i < max) {
        let elImage = document.getElementById('attract-group-image-' + i)
        let elButton = document.getElementById('attract-group-button-' + i)
        let elName = document.getElementById('attract-group-name-' + i)
        if (elImage && elButton && elName) {
          elImage.classList.add('fadedown')
          elButton.classList.add('fadedown')
        }
        i++
      }
    }
  }

  _animLoop(i, max) {
    if (this.props.display === 'attract') {
      if (i < max) {

        // clear last highlight
        let j = i - 1
        let elImage = document.getElementById('attract-group-image-' + j)
        let elButton = document.getElementById('attract-group-button-' + j)
        let elName = document.getElementById('attract-group-name-' + j)
        let elBarGroupName = document.getElementById('attract-group-name-dna-bar-' + j)
        if (elImage && elButton && elName) {
          elImage.classList.add('fadedown')
          elButton.classList.add('fadedown')
          elName.classList.add('fadedown')
          elImage.classList.remove('highlight')
          elButton.classList.remove('highlight')
          elName.classList.remove('highlight')
        }

        // set current highlight
        elImage = document.getElementById('attract-group-image-' + i)
        elButton = document.getElementById('attract-group-button-' + i)
        elName = document.getElementById('attract-group-name-' + i)
        elBarGroupName = document.getElementById('attract-group-name-dna-bar-' + i)
        if (elImage && elButton && elName && elBarGroupName) {
          elImage.classList.add('highlight')
          elButton.classList.add('highlight')
          elName.classList.add('highlight')
          elBarGroupName.classList.add('grow')
        }
        clearTimeout(this.timeoutIdAnimLoop)
        this.timeoutIdAnimLoop = setTimeout(() => {
          this._animLoop(i+1, max)
        }, 3000)
      } else {
        this._animNextSceneTransition(i-1, max)
      }
    }
  }

  _animNextSceneTransition(i, max) {
    if (this.props.display === 'attract') {

      let elImage = document.getElementById('attract-group-image-' + i)
      let elButton = document.getElementById('attract-group-button-' + i)
      let elName = document.getElementById('attract-group-name-' + i)
      if (elImage && elButton && elName) {
        elImage.classList.add('fadedown')
        elButton.classList.add('fadedown')
        elName.classList.add('fadedown')
        elImage.classList.remove('highlight')
        elButton.classList.remove('highlight')
        elName.classList.remove('highlight')
      }

      clearTimeout(this.timeoutIdHideGroups)
      this.timeoutIdHideGroups = setTimeout(() => {
        let groupsImage = document.getElementById('attract-groups-images')
        let groupsButton = document.getElementById('attract-groups-buttons')
        let groupsName = document.getElementById('attract-groups-names')
        let caseTitle = document.getElementById('attract-case-title')
        if (groupsImage && groupsButton && groupsName && caseTitle) {
          groupsImage.classList.add('fadedown')
          groupsButton.classList.add('fadedown')
          groupsName.classList.add('fadedown')
          caseTitle.classList.add('fadedown')
        }
      }, 300)

      clearTimeout(this.timeoutIdAnimCaseName)
      this.timeoutIdAnimCaseName = setTimeout(() => {
        this._initAnimCaseName(max)
      }, 1000)

    }
  }

  _initAnimCaseName(max) {
    if (this.props.display === 'attract') {

      this.setState({
        caseNameAnimating: true
      })

      let caseName = document.getElementById('attract-case-name-container')
      let caseNameText = document.getElementById('attract-case-name')
      let caseNameTranslations = document.getElementById('attract-case-name-translations')
      let caseNameTextSpanish = document.getElementById('attract-case-name-spanish')
      let caseNameTextChinese = document.getElementById('attract-case-name-chinese')
      let caseNameTextFiliino = document.getElementById('attract-case-name-filipino')
      let elBarCaseNameBottom = document.getElementById('attract-case-name-dna-bar-bottom')
      let elBarCaseNameTopA = document.getElementById('attract-case-name-dna-bar-top-a')
      let elBarCaseNameTopB = document.getElementById('attract-case-name-dna-bar-top-b')

      if (caseName && caseNameText && caseNameTranslations && caseNameTextSpanish && caseNameTextChinese && caseNameTextFiliino && elBarCaseNameBottom && elBarCaseNameTopA && elBarCaseNameTopB) {
        caseName.classList.add('come-and-go') // anim inc. delay
        caseNameTranslations.classList.add('come-and-go') // anim inc. delay
        caseNameText.classList.add('color-trans') // anim inc. delay
        caseNameTextSpanish.classList.add('color-trans') // anim inc. delay
        caseNameTextChinese.classList.add('color-trans') // anim inc. delay
        caseNameTextFiliino.classList.add('color-trans') // anim inc. delay
        elBarCaseNameBottom.classList.add('grow') // anim inc. delay
        elBarCaseNameTopA.classList.add('grow') // anim inc. delay
        elBarCaseNameTopB.classList.add('grow') // anim inc. delay
      }

      clearTimeout(this.timeoutIdAnimPreReLoop)
      this.timeoutIdAnimPreReLoop = setTimeout(() => {
        this._animPreReLoop(max)
      }, 10000)

    }
  }

  _animPreReLoop(max) {
    if (this.props.display === 'attract') {

      this.setState({
        caseNameAnimating: false
      })

      this._resetAttractComponentsForLoop(max)

      clearTimeout(this.timeoutIdAnimReLoop)
      this.timeoutIdAnimReLoop = setTimeout(() => {
        this._animReLoop(max)
      }, 500)
    }
  }

  _animReLoop(max) {
    if (this.props.display === 'attract') {

      let groupsButton = document.getElementById('attract-groups-buttons')
      let groupsName = document.getElementById('attract-groups-names')
      if (groupsButton && groupsName) {
        groupsButton.classList.add('highlight')
        groupsName.classList.add('highlight')
      }

      clearTimeout(this.timeoutIdAnimReLoopExtra)
      this.timeoutIdAnimReLoopExtra = setTimeout(() => {
        let caseTitle = document.getElementById('attract-case-title')
        let groupsImage = document.getElementById('attract-groups-images')
        if (caseTitle && groupsImage) {
          caseTitle.classList.add('highlight')
          groupsImage.classList.add('highlight')
        }
      }, 1000)

      clearTimeout(this.timeoutIdAnimLoopHelper)
      this.timeoutIdAnimLoopHelper = setTimeout(() => {
        let elName = document.getElementById('attract-group-name-0')
        let elBarGroupName = document.getElementById('attract-group-name-dna-bar-0')
        if (elName && elBarGroupName) {
          elName.classList.add('highlight')
          elBarGroupName.classList.add('grow')
        }
        let i = 1 // don't fade down first object initially
        while (i < max) {
          let elImage = document.getElementById('attract-group-image-' + i)
          let elButton = document.getElementById('attract-group-button-' + i)
          let elName = document.getElementById('attract-group-name-' + i)
          if (elImage && elButton && elName) {
            elImage.classList.add('fadedown')
            elButton.classList.add('fadedown')
          }
          i++
        }
      }, 2000)

      clearTimeout(this.timeoutIdAnimLoopReRe)
      this.timeoutIdAnimLoopReRe = setTimeout(() => {
        let caseTitle = document.getElementById('attract-case-title')
        let groupsImage = document.getElementById('attract-groups-images')
        let groupsButton = document.getElementById('attract-groups-buttons')
        let groupsName = document.getElementById('attract-groups-names')
        if (caseTitle && groupsImage && groupsButton && groupsName) {
          caseTitle.classList.remove('highlight')
          groupsImage.classList.remove('highlight')
          groupsButton.classList.remove('highlight')
          groupsName.classList.remove('highlight')
          caseTitle.style.opacity = 1;
          groupsImage.style.opacity = 1;
          groupsButton.style.opacity = 1;
          groupsName.style.opacity = 1;
        }
        this._animLoop(1, max)
      }, 5000)
    }
  }

  _resizeCaseNameToFit() {
    const output = document.getElementById('attract-case-name')
    if (output && (!this.state.updated)) {
      if (output.offsetHeight > 200) {
        output.style.fontSize = '110px'
        output.style.lineHeight = '122px'
        output.style.marginLeft = ((840 - output.offsetWidth) / 2) - 4 + 'px'
        output.style.marginRight = ((840 - output.offsetWidth) / 2) - 4 + 'px'
        output.style.display = 'inline-block'
      }
    }
  }

  render() {

    const _this = this
    let activeCase = []
    let activeCaseGroups = []
    if ((this.props.dataCases) && (this.props.activeCase)) {
      activeCase = this.props.dataCases.filter((obj) => {
        return obj['case_id'].toLowerCase() === _this.props.activeCase
      })
      if (this.props.dataCasesGroups && activeCase.length > 0) {
        activeCaseGroups = this.props.dataCasesGroups.filter((obj) => {
          return obj['case_nid'] === activeCase[0]['case_nid']
        })
      }
    }

    let markupCaseNameEnglish = {__html: ''}
    let markupCaseNameSpanish = {__html: ''}
    let markupCaseNameChinese = {__html: ''}
    let markupCaseNameFilipino = {__html: ''}

    let markupCaseGroupsImages = []
    let markupCaseGroupsButtons = []
    let markupCaseGroupsNames = []

    if (activeCase[0]) {
      if (activeCase[0]['case_name_english']) {
        markupCaseNameEnglish = {__html: activeCase[0]['case_name_english']}
      }
      if (activeCase[0]['case_name_spanish']) {
        markupCaseNameSpanish = {__html: activeCase[0]['case_name_spanish']}
      }
      if (activeCase[0]['case_name_chinese']) {
        markupCaseNameChinese = {__html: activeCase[0]['case_name_chinese']}
      }
      if (activeCase[0]['case_name_filipino']) {
        markupCaseNameFilipino = {__html: activeCase[0]['case_name_filipino']}
      }
    }

    let caseGroupsImageStyle = {}
    activeCaseGroups.forEach((obj, i) => {
      if (
        obj['group_home_image_position_top'] &&
        obj['group_home_image_position_left'] &&
        obj['group_home_image_size'] &&
        obj['group_home_image']['uri']
      ) {
        caseGroupsImageStyle = {
          top: (obj['group_home_image_position_top'] * .01) * 1080 + 'px',
          left: obj['group_home_image_position_left'] + '%',
          width: obj['group_home_image_size'] + '%'
        }
        markupCaseGroupsImages.push(
          <img
            key={'attract-group-image-' + i}
            src={process.env.REACT_APP_LOCALIZED_PATH + '/localized-assets/styles/max-width-1080/public/' + obj['group_home_image']['uri'].replace('public://', '')}
            alt={obj['group_home_image']['alt']}
            style={caseGroupsImageStyle}
            id={'attract-group-image-' + i}
          />
        )
      }
      caseGroupsImageStyle = {}
    })

    let caseGroupsButtonStyle = {}
    let caseGroupsNameStyle = {}
    activeCaseGroups.forEach((obj, i) => {
      if (
        obj['group_home_button_position_top'] &&
        obj['group_home_button_position_left'] &&
        obj['group_home_button_size'] &&
        obj['group_attract_name_position_top'] &&
        obj['group_attract_name_position_left'] &&
        obj['group_nid'] &&
        obj['group_home_image']['alt']
      ) {
        let markupGroupName = {__html: obj['group_home_image']['alt']}
        caseGroupsButtonStyle = {
          top: (obj['group_home_button_position_top'] * .01) * 1080 + 'px',
          left: obj['group_home_button_position_left'] + '%',
          width: (obj['group_home_button_size'] * .01) * 1080 + 'px',
          height: (obj['group_home_button_size'] * .01) * 1080 + 'px'
        }
        caseGroupsNameStyle = {
          top: (obj['group_attract_name_position_top'] * .01) * 1080 + 'px',
          left: obj['group_attract_name_position_left'] + '%'
        }
        markupCaseGroupsButtons.push(
          <div
            key={'attract-group-button-' + i}
            className={'attract-group-button'}
            style={caseGroupsButtonStyle}
            id={'attract-group-button-' + i}
            onClick={(e) => _this.handlerInterruptAttractAndSelect(e, activeCaseGroups.length, obj['group_nid'], 'case')}
          />
        )
        markupCaseGroupsNames.push(
          <div
            key={'attract-group-name-' + i}
            >
            <div
              className={'attract-group-name'}
              style={caseGroupsNameStyle}
              id={'attract-group-name-' + i}
              onClick={(e) => _this.handlerInterruptAttractAndSelect(e, activeCaseGroups.length, obj['group_nid'], 'case')}
            >
              <div
                className={'attract-group-name-text'}
                dangerouslySetInnerHTML={markupGroupName}
              />
              <div
                className={'attract-group-name-dna-bar'}
                id={'attract-group-name-dna-bar-' + i}
              />
            </div>
          </div>
        )
      }
      caseGroupsButtonStyle = {}
      caseGroupsNameStyle = {}
    })

    let ctaSeeAllLanguageActive = {
      'english': '',
      'spanish': '',
      'chinese': '',
      'filipino': ''
    }
    if (this.props.dataUI) {
      let label = this.props.dataUI.filter((ui) => {
        return ui['label_id'] === 'cta-see-all'
      })
      if (label.length > 0) {
        if (label[0]['label_english'] &&
        label[0]['label_spanish'] &&
        label[0]['label_chinese'] &&
        label[0]['label_filipino']) {
          ctaSeeAllLanguageActive = {
            'english': label[0]['label_english'],
            'spanish': label[0]['label_spanish'],
            'chinese': label[0]['label_chinese'],
            'filipino': label[0]['label_filipino']
          }
        }
      }
    }

    return(

      <div
        id="attract"
        >
        <div
          id="attract-backplate"
          onClick={() => this.handlerInterruptAttract(activeCaseGroups.length)}
        />
        <div id="attract-mask-top" />
        <h1
          id="attract-case-title"
          dangerouslySetInnerHTML={markupCaseNameEnglish} />
        <div id="attract-groups-images">
          {markupCaseGroupsImages}
        </div>
        <div id="attract-groups-buttons">
          {markupCaseGroupsButtons}
        </div>
        <div id="attract-groups-names">
          {markupCaseGroupsNames}
        </div>
        <div
          id="case-cta-see-all"
          >
          {ctaSeeAllLanguageActive[this.props.language]}
        </div>
        <div id="attract-case-name-container">
          <div
            id="attract-case-name-dna-bar-top-a"
          />
          <div
            id="attract-case-name-dna-bar-top-b"
          />
          <span
            id="attract-case-name"
            dangerouslySetInnerHTML={markupCaseNameEnglish}
          />
        </div>
        <div
          id="attract-case-name-dna-bar-bottom"
        />
        <div id="attract-case-name-translations">
          <div
            id="attract-case-name-spanish"
            dangerouslySetInnerHTML={markupCaseNameSpanish}
          />
          <div
            id="attract-case-name-chinese"
            dangerouslySetInnerHTML={markupCaseNameChinese}
          />
          <div
            id="attract-case-name-filipino"
            dangerouslySetInnerHTML={markupCaseNameFilipino}
          />
        </div>
      </div>
    )

  }
}

export default Attract
