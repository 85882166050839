import React, {Component} from 'react'
import '../style/Translator.css'

class Translator extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectOpen: false
    }
  }

  componentDidMount() {
    this.props.handlerComponentLoaded('Translator')
  }

  render() {

    if (this.props.appContext === 'exhibit') {
      return(
        <div id="translator">
          <button
            className={this.props.display === 'credits' ? 'credits active' : 'credits'}
            onClick={() => this.props.handlerOpenCredits()}
            ><span>credits</span></button>
          <button
            className={this.props.language === 'filipino' ? 'active-filipino' : ''}
            onClick={(e) => this.props.handlerSelectLanguage(e, 'filipino')}
            ><span>Filipino</span></button>
          <button
            className={this.props.language === 'chinese' ? 'active-chinese' : ''}
            onClick={(e) => this.props.handlerSelectLanguage(e, 'chinese')}
            ><span>繁体中文</span></button>
          <button
            className={this.props.language === 'spanish' ? 'active-spanish' : ''}
            onClick={(e) => this.props.handlerSelectLanguage(e, 'spanish')}
            ><span>Español</span></button>
          <button
            className={this.props.language === 'english' ? 'active-english' : ''}
            onClick={(e) => this.props.handlerSelectLanguage(e, 'english')}
            ><span>English</span></button>
        </div>
      )
    } else {

      let strLanguage = {
        'english': 'Language',
        'spanish': 'Language',
        'chinese': 'Language',
        'filipino': 'Language'
      }
      if (this.props.dataUI) {
        let label = this.props.dataUI.filter((ui) => {
          return ui['label_id'] === 'language'
        })
        if (label.length > 0) {
          if (label[0]['label_english'] &&
          label[0]['label_spanish'] &&
          label[0]['label_chinese'] &&
          label[0]['label_filipino']) {
            strLanguage = {
              'english': label[0]['label_english'],
              'spanish': label[0]['label_spanish'],
              'chinese': label[0]['label_chinese'],
              'filipino': label[0]['label_filipino']
            }
          }
        }
      }

      const strLanguageActive = {
        'english': 'English',
        'spanish': 'Español',
        'chinese': '繁体中文',
        'filipino': 'Filipino'
      }
      return(
        <div id="translator">
          <div id="dropdown">
            <button
              className='language-select'
              onClick={() => this.props.handlerOpenLanguageSelect()}
              ><span
                className={this.props.translateMenuOpen ? 'select-tab open' : 'select-tab'}
                >{strLanguage[this.props.language]}:</span>
                <span
                  className={'active-language color-' + this.props.language}
                >
                  {strLanguageActive[this.props.language]}
                </span>
            </button>
            <div
              className={this.props.translateMenuOpen ? 'dropdown-content open' : 'dropdown-content'}
              >
              <button
                id="language-select-english"
                onClick={(e) => {this.props.handlerSelectLanguage(e, 'english'); this.props.handlerCloseLanguageSelect();}}
                ><span>English</span></button>
              <button
                id="language-select-spanish"
                onClick={(e) => {this.props.handlerSelectLanguage(e, 'spanish'); this.props.handlerCloseLanguageSelect();}}
                ><span>Español</span></button>
              <button
                id="language-select-chinese"
                onClick={(e) => {this.props.handlerSelectLanguage(e, 'chinese'); this.props.handlerCloseLanguageSelect();}}
                ><span>繁体中文</span></button>
              <button
                id="language-select-filipino"
                onClick={(e) => {this.props.handlerSelectLanguage(e, 'filipino'); this.props.handlerCloseLanguageSelect();}}
                ><span>Filipino</span></button>
            </div>
            <button
              className={this.props.display === 'credits' ? 'credits active' : 'credits'}
              onClick={() => this.props.handlerOpenCredits()}
              ><span>credits</span>
            </button>
          </div>
        </div>
      )
    }
  }
}

export default Translator
