import React, {Component} from 'react'
import '../style/Specimen.css'
import Slideshow from './Slideshow'

class Specimen extends Component {

  constructor(props) {
    super(props)

    this.state = {
      activeSpecimenSlideshowSlide: 0
    }
    this.handlerSelectSlide = this._onSelectSlide.bind(this)
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.display !== this.props.display) {
      if (this.props.display !== "specimen") {
        this.setState({
          activeSpecimenSlideshowSlide: 0
        })
      }
    }
  }

  _onSelectSlide(selectedIndex, e) {
    this.setState({
      activeSpecimenSlideshowSlide: selectedIndex
    })
  }

  componentDidMount() {
    this.props.handlerComponentLoaded('Specimen')
  }

  render() {

    const _this = this
    let activeObject = []
    let activeGroup = []
    let activeObjectLabels = []
    let activeObjectSecondaryMedia = []
    let activeSecondaryMedia = []
    if ((this.props.dataObjects) && (this.props.activeObject)) {
      activeObject = this.props.dataObjects.filter((obj) => {
        return obj['object_nid'] === _this.props.activeObject
      })
      if (this.props.dataGroupsObjects && this.props.dataGroups) {
        let nid = ''
        this.props.dataGroupsObjects.forEach((item) => {
          if (item['object_nid'] === _this.props.activeObject) {
            nid = item['group_nid']
          }
        })
        if (nid) {
          activeGroup = this.props.dataGroups.filter((obj) => {
            return obj['group_nid'] === nid
          })
        }
      }
      if (this.props.dataObjectLabels) {
        activeObjectLabels = this.props.dataObjectLabels.filter((obj) => {
          return obj['object_nid'] === _this.props.activeObject
        })
      }
      if (this.props.dataObjectsSecondaryMedia) {
        activeObjectSecondaryMedia = this.props.dataObjectsSecondaryMedia.filter((obj) => {
          return obj['object_nid'] === activeObject[0]['object_nid']
        })

        if (this.props.dataSecondaryMedia && activeObjectSecondaryMedia.length > 0) {
          this.props.dataSecondaryMedia.forEach((item) => {
            activeObjectSecondaryMedia.forEach((a) => {
              if (a['secondary_media_nid'] === item['secondary_media_nid']) {
                activeSecondaryMedia.push(item)
              }
            })
          })
        }

      }
    }

    let markupObjectInfo = []
    let markupLabels = []

    if (activeObject[0]) {
      let objectNumber = {__html: ''}
      let objectName = {__html: ''}
      let objectSupplementalName = {__html: ''}
      if (activeObject[0]['object_number']) {
        if (typeof activeObject[0]['object_number'] === 'string' || activeObject[0]['object_number'] instanceof String) {
          objectNumber = {__html: '<div class="object-number">' + activeObject[0]['object_number'] + '</div>'}
        }
      }
      if (activeObject[0]['object_name_' + this.props.language]) {
        let handleNameItalics = activeObject[0]['object_name_' + this.props.language]
        handleNameItalics = handleNameItalics.replace('&lt;i&gt;', '<i>')
        handleNameItalics = handleNameItalics.replace('&lt;/i&gt;', '</i>')
        objectName = {__html: '<div class="object-name">' + handleNameItalics + '</div>'}
      }
      if (activeObject[0]['object_supplemental_name_' + this.props.language]) {
        objectSupplementalName = {__html: '<div class="object-supplemental-name">' + activeObject[0]['object_supplemental_name_' + this.props.language] + '</div>'}
      }

      if (activeObjectLabels.length > 0) {
        activeObjectLabels.forEach((label, l) => {
          let objectLabel = {__html: ''}
          if (label['object_label_' + _this.props.language]) {
            objectLabel = {__html: '<div class="object-label">' +label['object_label_' + _this.props.language] + '</div>'}
            markupLabels.push(
              <div
                key={"label-" + l}
                className="object-info-label"
                dangerouslySetInnerHTML={objectLabel}
              />
            )
          }
        })
      }

      markupObjectInfo.push(
        <div
          key="0"
          className="object-info-item"
        >
          <div
            dangerouslySetInnerHTML={objectNumber}
          />
          <div
            dangerouslySetInnerHTML={objectName}
          />
          <div
            dangerouslySetInnerHTML={objectSupplementalName}
          />
          <div
            className="object-info-labels"
            >
            {markupLabels}
          </div>
        </div>
      )

      if (activeSecondaryMedia.length > 0) {
        let secondaryMediaNew = []
        activeSecondaryMedia.forEach((dsmMember, i) => {
          let smMemberNew = []
          this.props.dataObjectsSecondaryMedia.forEach((dosm) => {
            if (dosm['secondary_media_nid'] === dsmMember['secondary_media_nid']) {
              smMemberNew = dsmMember
              smMemberNew['rank'] = i
              secondaryMediaNew.push(smMemberNew)
            }
          })
        })
        secondaryMediaNew.sort((a,b) => (a.rank > b.rank) ? 1 : ((b.rank > a.rank) ? -1 : 0))
        activeSecondaryMedia = secondaryMediaNew
        activeSecondaryMedia.forEach((item, i)=>{
          let caption = {__html: ''}
          if (item['secondary_media_caption_' + this.props.language]) {
            caption = {__html: '<div class="object-media-caption">' + item['secondary_media_caption_' + this.props.language] + '</div>'}
          }
          markupObjectInfo.push(
            <div
              key="{i}"
              className="object-info-item"
            >
              <div
                dangerouslySetInnerHTML={caption}
              />
            </div>
          )
        })
      }

    }

    let homeButtonLabelLanguageActive = {
      'english': 'Home',
      'spanish': 'Home Spanish',
      'chinese': 'Home Chinese',
      'filipino': 'Home Filipino'
    }
    if (this.props.dataUI) {
      let label = this.props.dataUI.filter((ui) => {
        return ui['label_id'] === 'cta-home'
      })
      if (label.length > 0) {
        if (label[0]['label_english'] &&
        label[0]['label_spanish'] &&
        label[0]['label_chinese'] &&
        label[0]['label_filipino']) {
          homeButtonLabelLanguageActive = {
            'english': label[0]['label_english'],
            'spanish': label[0]['label_spanish'],
            'chinese': label[0]['label_chinese'],
            'filipino': label[0]['label_filipino']
          }
        }
      }
    }

    let activeGroupName = ''
    let activeGroupNavigationImage = ''
    let activeGroupNavigationImageStyle = {}

    if (activeGroup[0]) {
      if (activeGroup[0]['group_name_' + this.props.language]) {
        activeGroupName = activeGroup[0]['group_name_' + this.props.language]
      }
      if (activeGroup[0]['group_navigation_image']) {
        activeGroupNavigationImage = process.env.REACT_APP_LOCALIZED_PATH + '/localized-assets/styles/max-width-1080/public/' + activeGroup[0]['group_navigation_image']['uri'].replace('public://', '')
      }
      activeGroupNavigationImageStyle = {
        backgroundImage: "url(" + activeGroupNavigationImage + ")"
      }
    }

    if (this.props.appContext === 'exhibit') {
      return(
        <div id="specimen">
          <div id="btn-back"
            onClick={(e) => this.props.handlerBackObject(e)}
          />
          <div
            id="container-slideshow"
            >
            <Slideshow
              handlerComponentLoaded={this.props.handlerComponentLoaded}
              appContext={this.props.appContext}
              display={this.props.display}
              language={this.props.language}
              dataObject={activeObject}
              dataSecondaryMedia={activeSecondaryMedia}
              dataObjectsSecondaryMedia={this.props.dataObjectsSecondaryMedia}
              activeSpecimenSlideshowSlide={this.state.activeSpecimenSlideshowSlide}
              handlerSelectSlide={this.handlerSelectSlide}
              handlerTouchLast={this.props.handlerTouchLast}
            />
          </div>
          <div
            className="content-stacked"
            >
            <div
              className="object-info-container"
              >
                {markupObjectInfo[this.state.activeSpecimenSlideshowSlide]}
            </div>
            <div
              id="object-nav-home"
              onClick={(e) => this.props.handlerSelectCase(e, this.props.activeCase)}
            >
              <div
                className="object-nav-label"
                >{homeButtonLabelLanguageActive[this.props.language]}</div>
            </div>
            <div
              id="object-nav-group"
              onClick={(e) => this.props.handlerSelectGroup(e, this.props.activeGroup, 'case')}
            >
              <div
                className="object-nav-image"
                style={activeGroupNavigationImageStyle}
              />
              <div
                className="object-nav-label"
                >{activeGroupName}</div>
            </div>
          </div>
        </div>
      )
    } else {
      return(
        <div id="specimen">
          <div id="btn-back"
            onClick={(e) => this.props.handlerBackObject(e)}
          />
          <div
            id="container-slideshow"
            >
            <Slideshow
              handlerComponentLoaded={this.props.handlerComponentLoaded}
              appContext={this.props.appContext}
              display={this.props.display}
              language={this.props.language}
              dataObject={activeObject}
              dataSecondaryMedia={activeSecondaryMedia}
              dataObjectsSecondaryMedia={this.props.dataObjectsSecondaryMedia}
              activeSpecimenSlideshowSlide={this.state.activeSpecimenSlideshowSlide}
              handlerSelectSlide={this.handlerSelectSlide}
              handlerTouchLast={this.props.handlerTouchLast}
            />
          </div>
          <div
            className="content-stacked"
            >
            <div
              className="object-info-container"
              >
                {markupObjectInfo[this.state.activeSpecimenSlideshowSlide]}
            </div>
            <div
              id="object-nav-home"
              onClick={(e) => this.props.handlerSelectCase(e, this.props.activeCase)}
            >
              <div
                className="object-nav-label"
                >{homeButtonLabelLanguageActive[this.props.language]}</div>
            </div>
            <div
              id="object-nav-group"
              onClick={(e) => this.props.handlerSelectGroup(e, this.props.activeGroup, 'case')}
              style={activeGroupNavigationImageStyle}
            >
              <div
                className="object-nav-label"
                >{activeGroupName}</div>
            </div>
          </div>
        </div>
      )
    }

  }

}

export default Specimen
