import React, {Component} from 'react'
import '../style/CaseMenu.css'

class CaseMenu extends Component {

  componentDidMount() {
    this.props.handlerComponentLoaded('CaseMenu')
  }

  render() {

    const _this = this
    let cases = []

    if ((this.props.dataCases) && (this.props.dataCasesGroups)) {
      cases = this.props.dataCases.filter((item) => {
        return _this.props.dataCasesGroups.find((group) => {
          return group['case_nid'] === item['case_nid']
        })
      })
    }

    let headerLanguageActive = {
      'english': '',
      'spanish': '',
      'chinese': '',
      'filipino': ''
    }
    if (this.props.dataUI) {
      let label = this.props.dataUI.filter((ui) => {
        return ui['label_id'] === 'interactive-title'
      })
      if (label.length > 0) {
        if (label[0]['label_english'] &&
        label[0]['label_spanish'] &&
        label[0]['label_chinese'] &&
        label[0]['label_filipino']) {
          headerLanguageActive = {
            'english': label[0]['label_english'],
            'spanish': label[0]['label_spanish'],
            'chinese': label[0]['label_chinese'],
            'filipino': label[0]['label_filipino']
          }
        }
      }
    }

    let markupMenuCases = []
    cases.forEach((item, i) => {
      markupMenuCases.push(
        <div
          key={'case-menu-case' + i}
          className={'case'}
          onClick={(e) => _this.props.handlerSelectCase(e, item['case_id'])}
        >
          <div
            key={'case-menu-case-name' + i}
          >
            {item['case_name_' + _this.props.language]}
          </div>
        </div>
      )
    })

    let markupCaseMenuButton = []
    if (this.props.prevActiveCase) {
      markupCaseMenuButton.push(
        <div id="btn-close" key="case-menu-close-btn"
          onClick={(e) => this.props.handlerCloseCaseMenu(e, this.props.prevActiveCase)}
        />
      )
    }



    return(

      <div id="case-menu">
        {markupCaseMenuButton}
        <h1>{headerLanguageActive[this.props.language]}</h1>
        <div className={'content-stacked'}>
          {markupMenuCases}
        </div>
      </div>
    )

  }

}

export default CaseMenu
