import React, {Component} from 'react'
import '../style/GroupMenu.css'

class GroupMenu extends Component {

  componentDidMount() {
    this.props.handlerComponentLoaded('GroupMenu')
  }

  render() {

    const _this = this
    let activeCase = []
    let caseGroups = []
    let groups = []
    let groupObjects = []
    let objects = []

    if ((this.props.activeCase) && (this.props.dataCases)) {
      activeCase = this.props.dataCases.filter((obj) => {
        return obj['case_id'].toLowerCase() === this.props.activeCase
      })
      if (this.props.dataCasesGroups && activeCase.length > 0) {
        caseGroups = this.props.dataCasesGroups.filter((obj) => {
          return obj['case_nid'] === activeCase[0]['case_nid']
        })
        if (this.props.dataGroups) {
          caseGroups.forEach((item) => {
            let group = _this.props.dataGroups.filter((obj) => {
              return obj['group_nid'] === item['group_nid']
            })
            groups.push(group)
          })
          if (this.props.dataGroupsObjects) {
            groups.forEach((item) => {
              let object = _this.props.dataGroupsObjects.filter((obj) => {
                return obj['group_nid'] === item[0]['group_nid']
              })
              groupObjects.push(object)
            })
            if (this.props.dataObjects) {
              groupObjects.forEach((item) => {
                let arr = []
                item.forEach((oid, i) => {
                  let objs = _this.props.dataObjects.filter((obj) => {
                    return obj['object_nid'] === oid['object_nid']
                  })
                  arr.push(objs)
                })
                objects.push(arr)
              })
            }

            let objectsNew = []
            objects.forEach((objs, i) => {
              let objNew = []
              objs.forEach((obj, o) => {
                groupObjects.forEach((groups) => {
                  groups.forEach((group) => {
                    if (group['object_nid'] === obj[0]['object_nid']) {
                      obj[0]['object_rank'] = group['object_rank']
                      objNew[o] = obj[0]
                    }
                  })
                })
                objNew.sort((a,b) => (parseInt(a.object_rank) > parseInt(b.object_rank)) ? 1 : ((parseInt(b.object_rank) > parseInt(a.object_rank)) ? -1 : 0))
                objectsNew[i] = objNew
              })
            })
            objects = objectsNew
          }
        }
      }
    }

    let headerLanguageActive = {__html: ''}
    if (activeCase.length > 0) {
      if (activeCase[0]['case_name_' + _this.props.language]) {
        headerLanguageActive = {__html: activeCase[0]['case_name_' + _this.props.language]}
      }
    }
    let markupMenuGroups = []
    groups.forEach((group, i) => {
      let markupMenuGroupObjects = []
      if (objects[i]) {
        objects[i].forEach((object, j) => {
          if (
            group[0]['group_nid'] &&
            object['object_nid'] &&
            object['object_navigation_image']['uri'] &&
            object['object_name_' + _this.props.language] &&
            object['object_number'] &&
            object['object_supplemental_name_' + _this.props.language]
          ) {
            let handleNameItalics = object['object_name_' + _this.props.language]
            handleNameItalics = handleNameItalics.replace('&lt;i&gt;', '<i>')
            handleNameItalics = handleNameItalics.replace('&lt;/i&gt;', '</i>')
            let markupName = {__html: handleNameItalics}
            let markupNameSupp = {__html: object['object_supplemental_name_' + _this.props.language]}
            let markupOptionalGrouoNumber = []
            if (typeof object['object_number'] === 'string' || object['object_number'] instanceof String) {
              markupOptionalGrouoNumber.push(
                <h3
                  key={'header-number-'+i}
                  >{object['object_number']}</h3>
              )
            }
            markupMenuGroupObjects.push(
              <div
                key={'group-menu-group-object' + j}
                className={'object-list-item'}
                onClick={(e) => _this.props.handlerSelectObjectFromMenu(e, object['object_nid'], group[0]['group_nid'], 'group-menu')}
              >
                <img
                  key={'group-menu-group-object-image' + j}
                  src={process.env.REACT_APP_LOCALIZED_PATH + '/localized-assets/styles/max-width-1080/public/' + object['object_navigation_image']['uri'].replace('public://', '')}
                  alt={object['object_navigation_image']['alt']}
                />
                <div
                  key={'object-info-container-' + i}
                  className={'object-info-container'}
                >
                  {markupOptionalGrouoNumber}
                  <h4
                    key={'header-name-'+i}
                    dangerouslySetInnerHTML={markupName}
                  />
                  <h5
                    key={'header-name-supplemental-'+i}
                    dangerouslySetInnerHTML={markupNameSupp}
                  />
                </div>
              </div>
            )
          }
        })
      }
      if (
        group[0]['group_nid'] &&
        group[0]['group_navigation_image']['uri'] &&
        group[0]['group_name_' + _this.props.language]
      ) {
        markupMenuGroups.push(
          <div
            key={'group-menu-group' + i}
          >
            <div
              key={'group-menu-group-header' + i}
              className={'object-list-item'}
              onClick={(e) => _this.props.handlerSelectGroup(e, group[0]['group_nid'], 'group-menu')}
            >
              <img
                key={'group-menu-group-header-image' + i}
                src={process.env.REACT_APP_LOCALIZED_PATH + '/localized-assets/styles/max-width-1080/public/' + group[0]['group_navigation_image']['uri'].replace('public://', '')}
                alt={group[0]['group_navigation_image']['alt']}
              />
              <div
                key={'object-info-container-' + i}
                className={'object-info-container'}
              >
                <h2
                  key={'group-menu-group-header-name' + i}
                >
                  {group[0]['group_name_' + _this.props.language]}
                </h2>
              </div>
            </div>
            <div
              key={'group-menu-group-objects' + i}
            >
                {markupMenuGroupObjects}
            </div>
          </div>
        )
      }
    })

    return(

      <div id="group-menu">
        <div id="btn-close"
          onClick={() => this.props.handlerCloseGroupMenu()}
        />
        <h1
          dangerouslySetInnerHTML={headerLanguageActive}
        />
        <div className={'content-stacked'}>
          {markupMenuGroups}
        </div>
      </div>
    )

  }

}

export default GroupMenu
