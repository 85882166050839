import React, {Component} from 'react'
import '../style/Case.css'

class Case extends Component {

  componentDidMount() {
    this.props.handlerComponentLoaded('Case')
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.display !== prevProps.display) {
      const _this = this
      let activeCase = []
      let activeCaseGroups = []
      if ((this.props.dataCases) && (this.props.activeCase)) {
        activeCase = this.props.dataCases.filter((obj) => {
          return obj['case_id'].toLowerCase() === _this.props.activeCase
        })
        if (this.props.dataCasesGroups && activeCase.length > 0) {
          activeCaseGroups = this.props.dataCasesGroups.filter((obj) => {
            return obj['case_nid'] === activeCase[0]['case_nid']
          })
          if (this.props.display !== 'error') {
            if (activeCaseGroups.length < 1) {
              this.props.handlerDataError('Case.js - not enough activeCaseGroups')
            }
          }
        }
      }
    }
  }

  render() {

    const _this = this
    let activeCase = []
    let activeCaseGroups = []
    if ((this.props.dataCases) && (this.props.activeCase)) {
      activeCase = this.props.dataCases.filter((obj) => {
        return obj['case_id'].toLowerCase() === _this.props.activeCase
      })
      if (this.props.dataCasesGroups && activeCase.length > 0) {
        activeCaseGroups = this.props.dataCasesGroups.filter((obj) => {
          return obj['case_nid'] === activeCase[0]['case_nid']
        })
      }
    }

    let markupCaseName = {__html: ''}

    let markupCaseGroupsImages = []
    let markupCaseGroupsButtons = []

    let ctaSeeAllLanguageActive = {
      'english': '',
      'spanish': '',
      'chinese': '',
      'filipino': ''
    }
    if (this.props.dataUI) {
      let label = this.props.dataUI.filter((ui) => {
        return ui['label_id'] === 'cta-see-all'
      })
      if (label.length > 0) {
        if (label[0]['label_english'] &&
        label[0]['label_spanish'] &&
        label[0]['label_chinese'] &&
        label[0]['label_filipino']) {
          ctaSeeAllLanguageActive = {
            'english': label[0]['label_english'],
            'spanish': label[0]['label_spanish'],
            'chinese': label[0]['label_chinese'],
            'filipino': label[0]['label_filipino']
          }
        }
      }
    }

    if (activeCase[0]) {
      if (activeCase[0]['case_name_' + this.props.language]) {
        markupCaseName = {__html: activeCase[0]['case_name_' + this.props.language]}
      }
    }

    let caseGroupsImageStyle = {}
    let intLeftWorld = .985
    activeCaseGroups.forEach((obj, i) => {
      if (
        obj['group_home_image_position_top'] &&
        obj['group_home_image_position_left'] &&
        obj['group_home_image_size'] &&
        obj['group_home_image']['uri']
      ) {
        if (obj['group_home_image_position_left'] >= 70) {
          intLeftWorld = 1
        }
        if (_this.props.appContext === 'exhibit') {
          caseGroupsImageStyle = {
            top: (obj['group_home_image_position_top'] * .01) * 1080 + 'px',
            left: obj['group_home_image_position_left'] + '%',
            width: obj['group_home_image_size'] + '%'
          }
        } else {
          caseGroupsImageStyle = {
            top: obj['group_home_image_position_top'] * .93 + 'vw',
            left: obj['group_home_image_position_left'] * intLeftWorld + 'vw',
            width: obj['group_home_image_size'] * .89 + 'vw'
          }
        }
        markupCaseGroupsImages.push(
          <img
            key={'case-group-image-' + i}
            src={process.env.REACT_APP_LOCALIZED_PATH + '/localized-assets/styles/max-width-1080/public/' + obj['group_home_image']['uri'].replace('public://', '')}
            alt={obj['group_home_image']['alt']}
            style={caseGroupsImageStyle}
          />
        )
      }
      caseGroupsImageStyle = {}
    })

    let caseGroupsButtonStyle = {}
    activeCaseGroups.forEach((obj, i) => {
      if (
        obj['group_home_button_position_top'] &&
        obj['group_home_button_position_left'] &&
        obj['group_home_button_size'] &&
        obj['group_nid']
      ) {
        if (_this.props.appContext === 'exhibit') {
          caseGroupsButtonStyle = {
            top: (obj['group_home_button_position_top'] * .01) * 1080 + 'px',
            left: obj['group_home_button_position_left'] + '%',
            width: (obj['group_home_button_size'] * .01) * 1080 + 'px',
            height: (obj['group_home_button_size'] * .01) * 1080 + 'px'
          }
        } else {
          caseGroupsButtonStyle = {
            top: obj['group_home_button_position_top'] * .925 + 'vw',
            left: obj['group_home_button_position_left'] * 1 + 'vw',
            width: obj['group_home_button_size'] * .9 + 'vw',
            height: obj['group_home_button_size'] * .9 + 'vw'
          }
        }
        markupCaseGroupsButtons.push(
          <div
            key={'case-group-button-' + i}
            style={caseGroupsButtonStyle}
            onClick={(e) => _this.props.handlerSelectGroup(e, obj['group_nid'], 'case')}
          />
        )
      }
      caseGroupsButtonStyle = {}
    })

    return(

      <div id="case">
        <h1 dangerouslySetInnerHTML={markupCaseName} />
        <div id="case-groups-images">
          {markupCaseGroupsImages}
        </div>
        <div id="case-groups-buttons">
          {markupCaseGroupsButtons}
        </div>
        <div
          id="case-cta-see-all"
          onClick={() => this.props.handlerSelectGroupMenu()}
          >
          {ctaSeeAllLanguageActive[this.props.language]}
        </div>
        <div
          id="case-cta-world-menu"
          onClick={() => this.props.handlerSelectCaseMenu()}
          >
        </div>
      </div>
    )

  }
}

export default Case
