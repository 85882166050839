import React, {Component} from 'react'
import '../style/Loader.css'

class Loader extends Component {

  componentDidUpdate(prevProps, prevState) {
    if (
    (prevProps.loadedAttract !== this.props.loadedAttract) ||
    (prevProps.loadedCase !== this.props.loadedCase) ||
    (prevProps.loadedCaseMenu !== this.props.loadedCaseMenu) ||
    (prevProps.loadedCredits !== this.props.loadedCredits) ||
    (prevProps.loadedError !== this.props.loadedError) ||
    (prevProps.loadedGroup !== this.props.loadedGroup) ||
    (prevProps.loadedGroupMenu !== this.props.loadedGroupMenu) ||
    (prevProps.loadedSlideshow !== this.props.loadedSlideshow) ||
    (prevProps.loadedSpecimen !== this.props.loadedSpecimen) ||
    (prevProps.loadedTranslator !== this.props.loadedTranslator)
    ) {
      if (this.props.loadedApp) {
        if (
          this.props.loadedAttract &&
          this.props.loadedCase &&
          this.props.loadedCaseMenu &&
          this.props.loadedCredits &&
          this.props.loadedError &&
          this.props.loadedGroup &&
          this.props.loadedGroupMenu &&
          this.props.loadedSlideshow &&
          this.props.loadedSpecimen &&
          this.props.loadedTranslator
        ) {
          window.addEventListener('load', (event) => {
            setTimeout(()=>{
              this.props.handlerAllComponentsLoaded()
            }, 3000)
          })
        }
      }
    }
  }

  render() {

    return (
      <div id="loader">
        <h1>Hidden Wonders</h1>
        <h2>Loading...</h2>
        <h3>English</h3>
        <h3>中國人</h3>
        <h3>Filipino</h3>
        <h3>Español</h3>
      </div>
    )

  }
}

export default Loader
