import React, {Component} from 'react'
import '../style/Slideshow.css'
import { Carousel } from 'react-bootstrap'
import Video from './Video'
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch'

class Slideshow extends Component {

  constructor(props) {
    super(props)
    this.state = {
      key: 1, // force carousel rerender for slide 0 reset on slideshow exit
      panDisabled: true
    }
    this.transformRef = React.createRef()
    this.timeoutId = null
  }

  _enablePan() {
    if (this.transformRef.current) {
      this.setState({
        panDisabled: false
      })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.display !== this.props.display) {
      if (this.props.display !== "object") {
        this.setState({
          key: this.state.key + 1,
          panDisabled: true
        })
        if (this.transformRef.current) {
          this.transformRef.current.resetTransform()
        }
      }
    } else if (this.props.activeSpecimenSlideshowSlide !== prevProps.activeSpecimenSlideshowSlide) {
      if (this.transformRef.current) {
        this.transformRef.current.resetTransform()
        this.setState({
          panDisabled: true
        })
      }
    }
  }

  componentDidMount() {
    this.props.handlerComponentLoaded('Slideshow')
  }

  render() {

    const _this = this

    const hiResPath = 'max-width-2160'
    const loResPath = 'max-width-1080'
    let contextResPath = hiResPath
    if (this.props.appContext === 'world') {
      contextResPath = loResPath
    }

    let carouselItems = []

    if (this.props.dataObject.length === 1) {
      // first carousel item is specimen hero and info
      if (
        this.props.dataObject[0]['object_hero_image'] &&
        this.props.dataObject[0]['object_name_' + this.props.language] &&
        this.props.dataObject[0]['object_supplemental_name_' + this.props.language]
      ) {
        carouselItems.push(
          <Carousel.Item
            key={'carousel-item-hero'}
            >
              <div
                className="slideshow-slide-image"
                id={'slideshow-hero-image'}
              >
                <TransformWrapper
                  panning={{"disabled": + this.state.panDisabled, "velocityDisabled": true}}
                  doubleClick={{"disabled": true}}
                  centerZoomedOut={true}
                  initialScale={1}
                  maxScale={3}
                  initialPositionX={0}
                  initialPositionY={0}
                  ref={this.transformRef}
                  onZoomStart={() => {
                    this._enablePan()
                  }}
                  onZoomStop={(e) => {
                    if (e.state.scale > 2) {
                      this.transformRef.current.centerView(2)
                    }
                  }}
                  >
                  {({ resetTransform, centerView }) => (
                    <React.Fragment>
                      <TransformComponent>
                        <div className="image">
                          <img
                            key={'carousel-image-hero'}
                            src={process.env.REACT_APP_LOCALIZED_PATH + '/localized-assets/styles/' + contextResPath + '/public/' + this.props.dataObject[0]['object_hero_image']['uri'].replace('public://', '')}
                            alt={this.props.dataObject[0]['object_hero_image']['alt']}
                          />
                        </div>
                      </TransformComponent>
                    </React.Fragment>
                  )}
                </TransformWrapper>
              </div>
          </Carousel.Item>
        )
      }
    }

    if (this.props.dataSecondaryMedia) {
      // more carousel items from secondary media + captions
      this.props.dataSecondaryMedia.forEach((item, i) => {
        if (
          item['secondary_media_nid'] &&
          (item['secondary_media_image'] || item['secondary_media_video'])
        ) {
          if (item['secondary_media_image']['filemime']) {
            carouselItems.push(
              <Carousel.Item
                key={'carousel-item-' + i}
                >
                  <div
                    className="slideshow-slide-image"
                    id={'slideshow-image-' + i}
                  >
                    <TransformWrapper
                      panning={{"disabled": + this.state.panDisabled, "velocityDisabled": true}}
                      doubleClick={{"disabled": true}}
                      centerZoomedOut={true}
                      initialScale={1}
                      maxScale={3}
                      initialPositionX={0}
                      initialPositionY={0}
                      ref={this.transformRef}
                      onZoomStart={() => {
                        this._enablePan()
                      }}
                      onZoomStop={(e) => {
                        if (e.state.scale > 2) {
                          this.transformRef.current.centerView(2)
                        }
                      }}
                      >
                      {({ resetTransform, centerView }) => (
                        <React.Fragment>
                          <TransformComponent>
                            <div className="image">
                              <img
                                key={'carousel-image-' + i}
                                src={process.env.REACT_APP_LOCALIZED_PATH + '/localized-assets/styles/' + contextResPath + '/public/' + item['secondary_media_image']['uri'].replace('public://', '')}
                                alt={item['secondary_media_image']['alt']}
                              />
                            </div>
                          </TransformComponent>
                        </React.Fragment>
                      )}
                    </TransformWrapper>
                  </div>
              </Carousel.Item>
            )
          } else if (item['secondary_media_video']['filemime'] && item['secondary_media_poster_image']['filemime']) {
            carouselItems.push(
              <Carousel.Item
                key={'carousel-item-' + i}
                >
                <div
                  className="slideshow-slide-image"
                  id={'slideshow-image-' + i}
                >
                  <Video
                    posterId={'poster-image-' + i}
                    posterImageSrc={process.env.REACT_APP_LOCALIZED_PATH + '/localized-assets/styles/' + contextResPath + '/public/' + item['secondary_media_poster_image']['uri'].replace('public://', '')}
                    posterImageAlt={item['secondary_media_poster_image']['alt']}
                    handlerTouchLast={_this.props.handlerTouchLast}
                    activeIndex={_this.props.activeSpecimenSlideshowSlide}
                    vid={'video-' + item['secondary_media_nid']}
                    source={process.env.REACT_APP_LOCALIZED_PATH + '/localized-assets/' + item['secondary_media_video']['uri'].replace('public://', '')}
                  />
                </div>
              </Carousel.Item>
            )
          } else if (item['secondary_media_video']['filemime']) {
            carouselItems.push(
              <Carousel.Item
                key={'carousel-item-' + i}
                >
                <div
                  className="slideshow-slide-image"
                  id={'slideshow-image-' + i}
                >
                  <Video
                    handlerTouchLast={_this.props.handlerTouchLast}
                    activeIndex={_this.props.activeSpecimenSlideshowSlide}
                    vid={'video-' + item['secondary_media_nid']}
                    source={process.env.REACT_APP_LOCALIZED_PATH + '/localized-assets/' + item['secondary_media_video']['uri'].replace('public://', '')}
                  />
                </div>
              </Carousel.Item>
            )
          }
        }
      })
    }

    return (
      <div id="slideshow">
        <Carousel
          touch={false}
          key={this.state.key}
          activeIndex={this.props.activeSpecimenSlideshowSlide}
          onSelect={this.props.handlerSelectSlide}
          interval={1000000}
          controls={carouselItems.length > 1}
          >
          {carouselItems}
        </Carousel>
      </div>
    )

  }
}

export default Slideshow
