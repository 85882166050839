import React, {Component} from 'react'
import '../style/Group.css'

class Group extends Component {

  componentDidMount() {
    this.props.handlerComponentLoaded('Group')
  }

  render() {

    const _this = this
    let activeGroup = []
    let activeGroupObjects = []
    let activeObjects = []
    if ((this.props.dataGroups) && (this.props.activeGroup)) {
      activeGroup = this.props.dataGroups.filter((obj) => {
        return obj['group_nid'] === _this.props.activeGroup
      })
      if (this.props.dataGroupsObjects) {
        activeGroupObjects = this.props.dataGroupsObjects.filter((obj) => {
          return obj['group_nid'] === activeGroup[0]['group_nid']
        })
        if (this.props.dataObjects) {
          this.props.dataObjects.forEach((item) => {
            activeGroupObjects.forEach((a) => {
              if (a['object_nid'] === item['object_nid']) {
                item['object_rank'] = a['object_rank']
                activeObjects.push(item)
              }
            })
          })
        }
      }
    }

    let markupGroupName = {__html: ''}
    let markupGroupDescription = {__html: ''}

    let markupGroupObjectsMosaicImages = []

    if (activeGroup[0]) {
      if (activeGroup[0]['group_name_' + this.props.language]) {
        markupGroupName = {__html: activeGroup[0]['group_name_' + this.props.language]}
      }
      if (activeGroup[0]['group_description_' + this.props.language]) {
        markupGroupDescription = {__html: activeGroup[0]['group_description_' + this.props.language]}
      }
    }

    let groupObjectsMosaicImageStyle = {}
    activeGroupObjects.forEach((obj, i) => {
      if (
        !obj['mosaic_image_position_top'] ||
        !obj['mosaic_image_position_left'] ||
        !obj['mosaic_image_size'] ||
        !obj['mosaic_image']['uri']
      ) { return }
      groupObjectsMosaicImageStyle = {
        //zIndex: 99 + i,
        top: obj['mosaic_image_position_top'] * .92 + 'vw',
        left: obj['mosaic_image_position_left'] + 'vw',
        width: obj['mosaic_image_size'] - 2 + 'vw'
      }
      markupGroupObjectsMosaicImages.push(
        <img
          key={'group-object-mosaic-image-' + i}
          src={process.env.REACT_APP_LOCALIZED_PATH + '/localized-assets/styles/max-width-1080/public/' + obj['mosaic_image']['uri'].replace('public://', '')}
          alt={obj['mosaic_image']['alt']}
          style={groupObjectsMosaicImageStyle}
          onClick={(e) => _this.props.handlerSelectObject(e, obj['object_nid'], 'group')}
          onTouchEnd={(e) => _this.props.handlerSelectObject(e, obj['object_nid'], 'group')}
        />
      )
      groupObjectsMosaicImageStyle = {}
    })

    let headerObjects = {
      'english': 'Objects in this group',
      'spanish': 'Objects in this group',
      'chinese': 'Objects in this group',
      'filipino': 'Objects in this group'
    }
    if (this.props.dataUI) {
      let label = this.props.dataUI.filter((ui) => {
        return ui['label_id'] === 'group-objects-header'
      })
      if (label.length > 0) {
        if (label[0]['label_english'] &&
        label[0]['label_spanish'] &&
        label[0]['label_chinese'] &&
        label[0]['label_filipino']) {
          headerObjects = {
            'english': label[0]['label_english'],
            'spanish': label[0]['label_spanish'],
            'chinese': label[0]['label_chinese'],
            'filipino': label[0]['label_filipino']
          }
        }
      }
    }

    let markupActiveObjects = []
    activeObjects.sort((a,b) => (parseInt(a.object_rank) > parseInt(b.object_rank)) ? 1 : ((parseInt(b.object_rank) > parseInt(a.object_rank)) ? -1 : 0))
    activeObjects.forEach((obj, i) => {
      if (
        obj['object_nid'] &&
        obj['object_number'] &&
        obj['object_name_' + _this.props.language] &&
        obj['object_supplemental_name_' + _this.props.language] &&
        obj['object_navigation_image']['uri']
      ) {
        let handleNameItalics = obj['object_name_' + _this.props.language]
        handleNameItalics = handleNameItalics.replace('&lt;i&gt;', '<i>')
        handleNameItalics = handleNameItalics.replace('&lt;/i&gt;', '</i>')
        let markupName = {__html: handleNameItalics}
        let markupNameSupp = {__html: obj['object_supplemental_name_' + _this.props.language]}
        let markupOptionalGrouoNumber = []
        if (typeof obj['object_number'] === 'string' || obj['object_number'] instanceof String) {
          markupOptionalGrouoNumber.push(
            <h3
              key={'header-number-'+i}
              >{obj['object_number']}</h3>
          )
        }
        markupActiveObjects.push(
          <div
            key={'object-' + i}
            className={'object-list-item'}
            onClick={(e) => _this.props.handlerSelectObject(e, obj['object_nid'], 'group')}
            >
            <img
              key={'object-list-image-' + i}
              src={process.env.REACT_APP_LOCALIZED_PATH + '/localized-assets/styles/max-width-1080/public/' + obj['object_navigation_image']['uri'].replace('public://', '')}
              alt={obj['object_navigation_image']['alt']}
            />
            <div
              key={'object-info-container-' + i}
              className={'object-info-container'}
              >
              {markupOptionalGrouoNumber}
              <h4
                key={'header-name-'+i}
                dangerouslySetInnerHTML={markupName}
              />
              <h5
                key={'header-name-supplemental-'+i}
                dangerouslySetInnerHTML={markupNameSupp}
              />
            </div>
          </div>
        )
      }
    })


    return(

      <div id="group">
        <div id="btn-back"
          //onClick={(e) => this.props.handlerBackGroup(e, this.props.prevDisplay)}
          onClick={(e) => this.props.handlerBackGroup(e)}
        />
        <div id="group-objects-mosaic-images">
          {markupGroupObjectsMosaicImages}
        </div>
        <div className={'content-stacked'}>
          <h1 dangerouslySetInnerHTML={markupGroupName} />
          <div
            id="group-description"
            dangerouslySetInnerHTML={markupGroupDescription} />
          <h2>{headerObjects[this.props.language]}</h2>
          {markupActiveObjects}
        </div>
      </div>
    )

  }

}

export default Group
