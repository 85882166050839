import React, {Component} from 'react'
import '../style/Video.css'

class Video extends Component {

  constructor(props) {
    super(props)
    this.state = {
      playing: false
    }
    this.media = null
    this.handlerPlayVideo = this._playVideo.bind(this)
    this.handlerPauseVideo = this._pauseVideo.bind(this)
    this.handlerVideoEnded = this._videoEnded.bind(this)
    this.handlerVideoPaused = this._videoPaused.bind(this)
    this.intervalIdVideoPlaying = null
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.activeIndex !== this.props.activeIndex) {
      this.media.currentTime = 0
      this.media.pause()
      this.setState({
        playing: false
      })
      this._showPoster()
    }
  }

  componentDidMount() {
    this._initMedia()
  }

  _initMedia() {
    this.media = document.getElementById(this.props.vid)
    this.media.addEventListener('ended', this.handlerVideoEnded)
    this.media.addEventListener('pause', this.handlerVideoPaused)
    this.media.currentTime = 0
  }

  _videoPlaying() {
    if (this.state.playing) {
      this.props.handlerTouchLast()
    }
  }

  _playVideo() {
    if (!this.state.playing) {
      this.setState({
        playing: true
      })
      this.media.play()
      this._hidePoster()
    }
    clearInterval(this.intervalIdVideoPlaying)
    this.intervalIdVideoPlaying = setInterval(() => this._videoPlaying(), 10000)
  }

  _pauseVideo() {
    this.media.pause()
    this.setState({
      playing: false
    })
  }

  _videoEnded() {
    this.media.currentTime = 0
    this.setState({
      playing: false
    })
    this.props.handlerTouchLast()
    this._showPoster()
  }

  _videoPaused() {
    this.setState({
      playing: false
    })
    this.props.handlerTouchLast()
  }

  _hidePoster() {
    if (document.getElementById(this.props.posterId)) {
      document.getElementById(this.props.posterId).style.display = 'none'
    }
  }

  _showPoster() {
    if (document.getElementById(this.props.posterId)) {
      document.getElementById(this.props.posterId).style.display = 'flex'
    }
  }

  render() {

    let posterImage = []

    if (this.props.posterImageSrc && this.props.posterImageAlt) {
      posterImage.push(
        <div
          key={this.props.posterId}
          id={this.props.posterId}
          className="poster-image"
          >
          <img
            src={this.props.posterImageSrc}
            alt={this.props.posterImageAlt}
          />
        </div>
      )
    }

    return (
      <div className="video">
        {posterImage}
        <video
          id={this.props.vid}
          preload='auto'
          >
          <source
            src={this.props.source + '#t=0.1'}
            type="video/mp4" />
        </video>
        <div
          className="slideshow-slide-video-control"
          >
            <div
              className={this.state.playing ? 'button-play hide' : 'button-play show'}
              onClick={() => this.handlerPlayVideo()}
            />
            <div
              className={this.state.playing ? 'button-pause show' : 'button-pause hide'}
              onClick={() => this.handlerPauseVideo()}
            />
        </div>
      </div>
    )

  }
}

export default Video
