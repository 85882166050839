import React, {Component} from 'react'
import '../style/Error.css'

class Error extends Component {

  componentDidMount() {
    this.props.handlerComponentLoaded('Error')
  }

  render() {

    return(
      <div id="error" />
    )
  }
}

export default Error
