import React, { Component } from 'react'
import 'whatwg-fetch'
import 'url-search-params-polyfill'
import '../style/App.css'
import Loader from './Loader'
import Attract from './Attract'
import Translator from './Translator'
import Credits from './Credits'
import Case from './Case'
import Group from './Group'
import GroupMenu from './GroupMenu'
import CaseMenu from './CaseMenu'
import Specimen from './Specimen'// a.k.a. "Object" (in CMS data)
import Error from './Error'

class App extends Component {
  constructor() {
    super()
    this.state = {
      loadedApp: true, // component
      loadedAttract: false, // component
      loadedCase: false, // component
      loadedCaseMenu: false, // component
      loadedCredits: false, // component
      loadedError: false, // component
      loadedGroup: false, // component
      loadedGroupMenu: false, // component
      loadedSlideshow: false, // component
      loadedSpecimen: false, // component
      loadedTranslator: false, // component
      appContext: 'world', // world || exhibit
      activeCase: false, // case id
      activeGroup: false, // nid
      activeObject: false, // nid
      display: 'loader', // attract (EXHIBIT ONLY) || case-menu (WORLD ONLY) || credits || case || group || group-menu || specimen || error
      prevDisplay: 'case', //used for back button navigation
      prevActiveCase: false, // case id
      prevActiveGroup: false, // nid
      prevActiveObject: false, // nid
      popupCredits: false,
      dataCases: null,
      dataCasesGroups: null,
      dataGroups: null,
      dataGroupsObjects: null,
      dataObjects: null,
      dataObjectLabels: null,
      dataObjectsSecondaryMedia: null,
      dataSecondaryMedia: null,
      dataCredits: null,
      dataUI: null,
      dataLoaded: false,
      currentLanguage: 'english', // english || spanish || chinese || filipino
      inactivityInt: 30000,
      dateLastTouch: null,
      translateMenuOpen: false
    }

    // Loader
    this.handlerAllComponentsLoaded = this._allComponentsLoaded.bind(this)
    // components
    this.handlerComponentLoaded = this._componentLoaded.bind(this)

    // Translator / Credits handlers
    this.handlerSelectLanguage = this._selectLanguage.bind(this)
    this.handlerOpenLanguageSelect = this._openLanguageSelect.bind(this)
    this.handlerCloseLanguageSelect = this._closeLanguageSelect.bind(this)
    this.handlerOpenCredits = this._openCredits.bind(this)
    this.handlerCloseCredits = this._closeCredits.bind(this)
    // Other Components
    this.handlerSelectGroup = this._selectGroup.bind(this)
    this.handlerSelectGroupMenu = this._selectGroupMenu.bind(this)
    this.handlerSelectCaseMenu = this._selectCaseMenu.bind(this)
    this.handlerCloseCaseMenu = this._closeCaseMenu.bind(this)
    this.handlerSelectObject = this._selectObject.bind(this)
    this.handlerSelectObjectFromMenu = this._selectObjectFromMenu.bind(this)
    this.handlerBackGroup = this._backGroup.bind(this)
    this.handlerSelectObject = this._selectObject.bind(this)
    this.handlerCloseGroupMenu = this._closeGroupMenu.bind(this)
    this.handlerSelectCase = this._selectCase.bind(this)
    this.handlerBackObject = this._backObject.bind(this)
    this.handlerTouchLast = this._touchLast.bind(this)
    this.handlerCloseAttract = this._closeAttract.bind(this)
    // Error
    this.handlerDataError = this._dataError.bind(this)

    this.timeoutIdLanguageSelect = null

  }

  _allComponentsLoaded() {
    // check for exhibit case display via index.html window property
    if (window.exhibit_case) {
      if ((typeof window.exhibit_case === 'string') || (window.exhibit_case instanceof String)) {
        this.setState({
          activeCase: window.exhibit_case.toLowerCase(),
          appContext: 'exhibit',
          display: 'case'
        })
        // attract mode inactivity check - ONLY for "exhibit" appContext!
        setInterval(() => this._inactivityCheck(), 5000)
      } else {
        this.setState({
          display: 'error'
        })
      }
    } else {
      // check for world case reference via url query
      const urlSearchParams = new URLSearchParams(window.location.search);
      if (urlSearchParams.has('case')) {
        this.setState({
          activeCase: urlSearchParams.get('case').toLowerCase(),
          display: 'case'
        })
        this._pseudoPageHistory('/case/' + urlSearchParams.get('case').toLowerCase())
      // check for exhibit case preview reference via url query (not public)
      } else if (urlSearchParams.has('exhibit')) {
        this.setState({
          activeCase: urlSearchParams.get('exhibit').toLowerCase(),
          appContext: 'exhibit',
          display: 'case'
        })
        // attract mode inactivity check - ONLY for "exhibit" appContext!
        setInterval(() => this._inactivityCheck(), 5000)
      } else {
        this.setState({
          display: 'case-menu'
        })
        this._pseudoPageHistory('/menu')
      }
    }

    // browser page navigation handling for world view
    const _this = this
    if (this.state.appContext === 'world') {
      window.addEventListener('popstate', (event) => {
        event.preventDefault()
        if (event.state) {
          _this._closeCredits()
          let ao = _this.state.prevActiveObject || _this.state.activeObject
          let ag = _this.state.prevActiveGroup || _this.state.activeGroup
          let ac = _this.state.prevActiveCase || _this.state.activeCase
          let page = event.state.page
          switch(_this.state.display) {
            case 'case-menu':
              _this.setState({
                activeCase: ac,
                prevActiveCase: false,
                display: 'case',
                prevDisplay: 'case-menu'
              })
              _this._pseudoPageHistory('/case/' + ac.toLowerCase())
              break
            case 'case':
              // 1 reroute for case self-reference
              // 4 routes to/from case
              // - case-menu
              // - group
              // - group-menu
              // - object (home nav)
              if (page.includes("/case/")) {
                _this.setState({
                  activeCase: false,
                  prevActiveCase: ac,
                  display: 'case-menu',
                  prevDisplay: 'case'
                })
                _this._pseudoPageHistoryReplace('/menu')
              } else if (page.includes("/menu")) {
                _this.setState({
                  activeCase: false,
                  prevActiveCase: ac,
                  display: 'case-menu',
                  prevDisplay: 'case'
                })
                _this._pseudoPageHistoryReplace('/menu')
              } else if (page.includes("/group/")) {
                _this.setState({
                  activeGroup: ag,
                  prevActiveGroup: false,
                  display: 'group',
                  prevDisplay: 'case'
                })
                _this._pseudoPageHistory('/group/' + ag)
              } else if (page.includes("/group-menu/")) {
                _this.setState({
                  display: 'group-menu',
                  prevDisplay: 'case'
                })
                _this._pseudoPageHistory('/group-menu/' + ac.toLowerCase())
              } else if (page.includes("/object/")) {
                _this.setState({
                  activeObject: ao,
                  prevActiveObject: false,
                  display: 'specimen',
                  prevDisplay: 'case'
                })
                _this._pseudoPageHistory('/object/' + ao)
              }
              break
            case 'group':
              // 1 reroute for group self-reference
              // 3 routes to/from group
              // - case
              // - group-menu
              // - object
              if (page.includes("/group/")) {
                _this.setState({
                  activeCase: ac,
                  prevActiveCase: false,
                  display: 'case',
                  prevDisplay: 'group'
                })
                _this._pseudoPageHistoryReplace('/case/' + ac.toLowerCase())
              } else if (page.includes("/case/")) {
                _this.setState({
                  activeCase: ac,
                  prevActiveCase: false,
                  display: 'case',
                  prevDisplay: 'group'
                })
                _this._pseudoPageHistoryReplace('/case/' + ac.toLowerCase())
              } else if (page.includes("/group-menu/")) {
                _this.setState({
                  display: 'group-menu',
                  prevDisplay: 'group'
                })
                _this._pseudoPageHistory('/group-menu/' + ac.toLowerCase())
              } else if (page.includes("/object/")) {
                _this.setState({
                  activeObject: ao,
                  prevActiveObject: false,
                  display: 'specimen',
                  prevDisplay: 'group'
                })
                _this._pseudoPageHistory('/object/' + ao)
              }
              break
            case 'group-menu':
              // 1 reroute for group-menu self-reference
              // 3 routes to/from group-menu
              // - case
              // - group
              // - object
              if (page.includes("/group-menu/")) {
                _this.setState({
                  activeCase: ac,
                  prevActiveCase: false,
                  display: 'case',
                  prevDisplay: 'group-menu'
                })
                _this._pseudoPageHistoryReplace('/case/' + ac.toLowerCase())
              } else if (page.includes("/case/")) {
                _this.setState({
                  activeCase: ac,
                  prevActiveCase: false,
                  display: 'case',
                  prevDisplay: 'group-menu'
                })
                _this._pseudoPageHistoryReplace('/case/' + ac.toLowerCase())
              } else if (page.includes("/group/")) {
                _this.setState({
                  activeGroup: ag,
                  prevActiveGroup: false,
                  display: 'group',
                  prevDisplay: 'group-menu'
                })
                _this._pseudoPageHistory('/group/' + ag)
              } else if (page.includes("/object/")) {
                _this.setState({
                  activeObject: ao,
                  prevActiveObject: false,
                  display: 'specimen',
                  prevDisplay: 'group-menu'
                })
                _this._pseudoPageHistory('/object/' + ao)
              }
              break
            case 'specimen':
              // 1 reroute for object self-reference
              // 3 routes to/from object
              // - case (home nav)
              // - group
              // - group-menu
              if (page.includes("/object/")) {
                _this.setState({
                  activeGroup: ag,
                  prevActiveGroup: false,
                  display: 'group',
                  prevDisplay: 'specimen'
                })
                _this._pseudoPageHistoryReplace('/group/' + ag)
              } else if (page.includes("/case/")) {
                _this.setState({
                  activeCase: ac,
                  prevActiveCase: false,
                  display: 'case',
                  prevDisplay: 'specimen'
                })
                _this._pseudoPageHistoryReplace('/case/' + ac.toLowerCase())
              } else if (page.includes("/group/")) {
                _this.setState({
                  activeGroup: ag,
                  prevActiveGroup: false,
                  display: 'group',
                  prevDisplay: 'specimen'
                })
                _this._pseudoPageHistoryReplace('/group/' + ag)
              } else if (page.includes("/group-menu/")) {
                _this.setState({
                  display: 'group-menu',
                  prevDisplay: 'specimen'
                })
                _this._pseudoPageHistoryReplace('/group-menu/' + ac.toLowerCase())
              }
              break
            default:
              break
          }
        }
      })
    }
  }

  _componentLoaded(c) {
    this.setState({
      ['loaded'+c]: true
    })
  }

  _dataError(e) {
    console.log('An error has occurred in the application: ' + e)
    this.setState ({
      display: 'error'
    })
  }

  _inactivityCheck() {
    var now = new Date()
    var check = new Date(now.getTime() - this.state.inactivityInt)
    if (this.state.dateLastTouch !== null) {
      if ((this.state.dateLastTouch < check) &&
      (this.state.dataLoaded) &&
      (this.state.appContext === 'exhibit')
    ) {
        this.setState({
          currentLanguage: 'english',
          display: 'attract',
          popupCredits: false,
          dateLastTouch: null
        })
      }
    }
  }

  _pseudoPageHistory(page) {
    let state = {'page': page}
    let title = ''
    let url = page
    if (this.state.appContext === 'world') {
      window.history.pushState(state, title, url)
    }
  }

  _pseudoPageHistoryReplace(page) {
    let state = {'page': page}
    let title = ''
    let url = page
    if (this.state.appContext === 'world') {
      window.history.replaceState(state, title, url)
    }
  }

  _closeAttract() {
    this.setState({
      display: 'case'
    })
  }

  _getLocalizedData(name, filename) {
    // use local data if running in production environment
    // otherwise use data from remote production environment
    let url = process.env.REACT_APP_LOCALIZED_PATH + '/localized-data/' + filename
    fetch(url, {
      mode: 'cors',
      headers : {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }).then((response) => {
      return response.json()
    }).then((data) => {
      this.setState ({
        ['data' + name]: data
      })
    }).catch((ex) => {
      console.log('Initital JSON ' + url + ' fetch failed', ex)
    })
  }

  _selectLanguage(e, lang) {
    e.preventDefault()
    this.setState({
      currentLanguage: lang
    })
  }

  _openLanguageSelect() {
    this.setState({
      translateMenuOpen: true
    })
    clearTimeout(this.timeoutIdLanguageSelect)
    this.timeoutIdLanguageSelect = setTimeout(() => this._closeLanguageSelect(), 3000)
  }
  _closeLanguageSelect() {
    this.setState({
      translateMenuOpen: false
    })
  }

  // world only from case menu
  _selectCase(e, cid) {
    e.preventDefault()
    this.setState({
      activeCase: cid.toLowerCase(),
      prevActiveCase: this.state.activeCase,
      display: 'case',
      prevDisplay: 'case-menu'
    })
    setTimeout(() => window.scrollTo(0, -100), 100)
    this._pseudoPageHistory('/case/' + cid.toLowerCase())
  }

  _selectCaseMenu() {
    this.setState({
      activeCase: false,
      prevActiveCase: this.state.activeCase,
      display: 'case-menu',
      prevDisplay: 'case'
    })
    setTimeout(() => window.scrollTo(0, -100), 100)
    this._pseudoPageHistory('/menu')
  }

  _closeCaseMenu(e, cid) {
    e.preventDefault()
    this.setState({
      activeCase: cid,
      display: 'case',
      prevDisplay: 'case-menu'
    })
    setTimeout(() => window.scrollTo(0, -100), 100)
    this._pseudoPageHistory('/case/' + cid.toLowerCase())
  }

  _selectGroup(e, nid, prev) {
    e.preventDefault()
    this.setState({
      activeGroup: nid,
      prevActiveGroup: false,
      display: 'group',
      prevDisplay: prev
    })
    setTimeout(() => window.scrollTo(0, -100), 100)
    this._pseudoPageHistory('/group/' + nid)
  }

  _backGroup(e) {
    e.preventDefault()
    let prev = 'case'
    if (this.state.prevDisplay === 'group-menu') {
      prev = 'group-menu'
    }
    this.setState({
      activeGroup: false,
      prevActiveGroup: this.state.activeGroup,
      display: prev,
      prevDisplay: 'group'
    })
    setTimeout(() => window.scrollTo(0, -100), 100)
    this._pseudoPageHistory('/' + prev +  '/' + this.state.activeCase)
  }

  _selectGroupMenu() {
    this.setState({
      display: 'group-menu',
      prevDisplay: 'case'
    })
    setTimeout(() => window.scrollTo(0, -100), 100)
    this._pseudoPageHistory('/group-menu/' + this.state.activeCase.toLowerCase())
  }

  _closeGroupMenu() {
    this.setState({
      display: 'case',
      prevDisplay: 'group-menu'
    })
    setTimeout(() => window.scrollTo(0, 0), 100)
    this._pseudoPageHistory('/case/' + this.state.activeCase)
  }

  _selectObject(e, nid, prev) {
    e.preventDefault()
    this.setState({
      activeObject: nid,
      prevActiveObject: false,
      display: 'specimen',
      prevDisplay: prev
    })
    setTimeout(() => window.scrollTo(0, -100), 100)
    this._pseudoPageHistory('/object/' + nid)
  }

  _selectObjectFromMenu(e, nid, gid, prev) {
    e.preventDefault()
    this.setState({
      activeObject: nid,
      activeGroup: gid,
      prevActiveObject: false,
      prevActiveGroup: false,
      display: 'specimen',
      prevDisplay: prev
    })
    setTimeout(() => window.scrollTo(0, -100), 100)
    this._pseudoPageHistory('/object/' + nid)
  }

  _backObject(e) {
    e.preventDefault()
    let prev = 'group'
    if (this.state.prevDisplay === 'group-menu') {
      prev = 'group-menu'
    }
    this.setState({
      activeObject: false,
      prevActiveObject: this.state.activeObject,
      display: prev,
      prevDisplay: 'specimen'
    })
    setTimeout(() => window.scrollTo(0, -100), 100)
    this._pseudoPageHistory('/' + prev + '/' + this.state.activeGroup)
  }

  _openCredits() {
    this.setState({
      popupCredits: true
    })
    setTimeout(() => window.scrollTo(0, -100), 100)
  }
  _closeCredits() {
    this.setState({
      popupCredits: false
    })
  }

  _touchLast() {
    this.setState({
      dateLastTouch: new Date(),
    })
    if (this.state.translateMenuOpen) {
      clearTimeout(this.timeoutIdLanguageSelect)
      this.timeoutIdLanguageSelect = setTimeout(() => this._closeLanguageSelect(), 200)
    }
  }

  componentDidMount() {
    this._getLocalizedData("Cases", "digital-ids-cases.json")
    this._getLocalizedData("CasesGroups", "digital-ids-cases-groups.json")
    this._getLocalizedData("Groups", "digital-ids-groups.json")
    this._getLocalizedData("GroupsObjects", "digital-ids-groups-objects.json")
    this._getLocalizedData("Objects", "digital-ids-objects.json")
    this._getLocalizedData("ObjectLabels", "digital-ids-object-labels.json")
    this._getLocalizedData("ObjectsSecondaryMedia", "digital-ids-objects-secondary-media.json")
    this._getLocalizedData("SecondaryMedia", "digital-ids-secondary-media.json")
    this._getLocalizedData("Credits", "digital-ids-credits.json")
    this._getLocalizedData("UI", "digital-ids-ui.json")
    this.setState({
      loadedApp: true,
      dataLoaded: true,
      dateLastTouch: new Date()
    })
    // prevent pinch-zoom in environments that don't respect meta restrictions
    document.addEventListener('touchmove', e => {
      if (e.touches.length > 1) {
        e.preventDefault()
      }
    }, {passive: false})
  }

  render() {

    return (
      <div
        id="app"
        className={this.state.appContext}
        onTouchStart={this.handlerTouchLast}
        >
        <div id="container-loader"
          className={this.state.display !== 'loader' ? 'hide' : ''}>
          <Loader
            loadedApp={this.state.loadedApp}
            loadedAttract={this.state.loadedAttract}
            loadedCase={this.state.loadedCase}
            loadedCaseMenu={this.state.loadedCaseMenu}
            loadedCredits={this.state.loadedCredits}
            loadedError={this.state.loadedError}
            loadedGroup={this.state.loadedGroup}
            loadedGroupMenu={this.state.loadedGroupMenu}
            loadedSlideshow={this.state.loadedSlideshow}
            loadedSpecimen={this.state.loadedSpecimen}
            loadedTranslator={this.state.loadedTranslator}
            handlerAllComponentsLoaded={this.handlerAllComponentsLoaded}
          />
        </div>
        <div id="container-translator"
          className={(this.state.display !== 'loader') ? 'show' : ''}>
          <Translator
            handlerComponentLoaded={this.handlerComponentLoaded}
            appContext={this.state.appContext}
            display={this.state.display}
            language={this.state.currentLanguage}
            translateMenuOpen={this.state.translateMenuOpen}
            handlerSelectLanguage={this.handlerSelectLanguage}
            handlerOpenLanguageSelect={this.handlerOpenLanguageSelect}
            handlerCloseLanguageSelect={this.handlerCloseLanguageSelect}
            handlerOpenCredits={this.handlerOpenCredits}
            dataUI={this.state.dataUI}
           />
        </div>
        <div id="container-attract"
          className={this.state.display === 'attract' ? 'show' : ''}>
          <Attract
            handlerComponentLoaded={this.handlerComponentLoaded}
            language={this.state.currentLanguage}
            display={this.state.display}
            activeCase={this.state.activeCase}
            dataCases={this.state.dataCases}
            dataCasesGroups={this.state.dataCasesGroups}
            dataUI={this.state.dataUI}
            handlerCloseAttract={this.handlerCloseAttract}
            handlerSelectGroup={this.handlerSelectGroup}
          />
        </div>
        <div id="container-credits"
          className={this.state.popupCredits === true ? 'show' : ''}>
          <Credits
            handlerComponentLoaded={this.handlerComponentLoaded}
            display={this.state.display}
            prevDisplay={this.state.prevDisplay}
            language={this.state.currentLanguage}
            activeCase={this.state.activeCase}
            dataCredits={this.state.dataCredits}
            dataCases={this.state.dataCases}
            dataCasesGroups={this.state.dataCasesGroups}
            dataUI={this.state.dataUI}
            handlerCloseCredits={this.handlerCloseCredits}
            handlerDataError={this.handlerDataError}
           />
        </div>
        <div id="container-case"
          className={(this.state.display === 'case' && !this.state.popupCredits) ? 'show' : ''}
          >
          <Case
            handlerComponentLoaded={this.handlerComponentLoaded}
            display={this.state.display}
            language={this.state.currentLanguage}
            appContext={this.state.appContext}
            activeCase={this.state.activeCase}
            dataCases={this.state.dataCases}
            dataCasesGroups={this.state.dataCasesGroups}
            dataUI={this.state.dataUI}
            handlerSelectGroup={this.handlerSelectGroup}
            handlerSelectGroupMenu={this.handlerSelectGroupMenu}
            handlerSelectCaseMenu={this.handlerSelectCaseMenu}
            handlerDataError={this.handlerDataError}
          />
        </div>
        <div id="container-group"
          className={(this.state.display === 'group' && !this.state.popupCredits) ? 'show' : ''}
          >
          <Group
            handlerComponentLoaded={this.handlerComponentLoaded}
            display={this.state.display}
            prevDisplay={this.state.prevDisplay}
            language={this.state.currentLanguage}
            activeCase={this.state.activeCase}
            activeGroup={this.state.activeGroup}
            dataGroups={this.state.dataGroups}
            dataGroupsObjects={this.state.dataGroupsObjects}
            dataObjects={this.state.dataObjects}
            dataUI={this.state.dataUI}
            handlerBackGroup={this.handlerBackGroup}
            handlerSelectObject={this.handlerSelectObject}
            handlerDataError={this.handlerDataError}
          />
        </div>
        <div id="container-group-menu"
          className={(this.state.display === 'group-menu' && !this.state.popupCredits) ? 'show' : ''}
          >
          <GroupMenu
            handlerComponentLoaded={this.handlerComponentLoaded}
            display={this.state.display}
            language={this.state.currentLanguage}
            activeCase={this.state.activeCase}
            activeGroup={this.state.activeGroup}
            dataCases={this.state.dataCases}
            dataCasesGroups={this.state.dataCasesGroups}
            dataGroups={this.state.dataGroups}
            dataGroupsObjects={this.state.dataGroupsObjects}
            dataObjects={this.state.dataObjects}
            dataUI={this.state.dataUI}
            handlerCloseGroupMenu={this.handlerCloseGroupMenu}
            handlerSelectGroup={this.handlerSelectGroup}
            handlerSelectObject={this.handlerSelectObject}
            handlerSelectObjectFromMenu={this.handlerSelectObjectFromMenu}
            handlerDataError={this.handlerDataError}
          />
        </div>
        <div id="container-case-menu"
          className={(this.state.display === 'case-menu' && !this.state.popupCredits) ? 'show' : ''}
          >
          <CaseMenu
            handlerComponentLoaded={this.handlerComponentLoaded}
            display={this.state.display}
            language={this.state.currentLanguage}
            dataCases={this.state.dataCases}
            dataCasesGroups={this.state.dataCasesGroups}
            dataUI={this.state.dataUI}
            prevActiveCase={this.state.prevActiveCase}
            handlerSelectCase={this.handlerSelectCase}
            handlerCloseCaseMenu={this.handlerCloseCaseMenu}
          />
        </div>
        <div id="container-specimen"
          className={(this.state.display === 'specimen' && !this.state.popupCredits) ? 'show' : ''}
          >
          <Specimen
            handlerComponentLoaded={this.handlerComponentLoaded}
            appContext={this.state.appContext}
            display={this.state.display}
            prevDisplay={this.state.prevDisplay}
            language={this.state.currentLanguage}
            activeCase={this.state.activeCase}
            activeGroup={this.state.activeGroup}
            activeObject={this.state.activeObject}
            dataGroups={this.state.dataGroups}
            dataObjects={this.state.dataObjects}
            dataGroupsObjects={this.state.dataGroupsObjects}
            dataObjectLabels={this.state.dataObjectLabels}
            dataObjectsSecondaryMedia={this.state.dataObjectsSecondaryMedia}
            dataSecondaryMedia={this.state.dataSecondaryMedia}
            dataUI={this.state.dataUI}
            handlerBackObject={this.handlerBackObject}
            handlerTouchLast={this.handlerTouchLast}
            handlerSelectCase={this.handlerSelectCase}
            handlerSelectGroup={this.handlerSelectGroup}
          />
        </div>
        <div id="container-error"
          className={this.state.display === 'error' ? 'show' : ''}>
          <Error
            handlerComponentLoaded={this.handlerComponentLoaded}
            appContext={this.state.appContext}
          />
        </div>
      </div>
    )
  }
}

export default App
